<template>
  <div class="recurring-main">
    <div class="recurring-payments">
      <h1>Recurring Payment User Experience Customized for the Philippines</h1>

      <div class="recurring-payments-info">
        <div class="recurring-payments-left-part">
          <li class="first-list-item">
            <p>
              Auto-charge via credit/debit cards and "auto-reminder"
              subscriptions via Gcash, GrabPay and bank transfers.
            </p>
          </li>
          <li class="first-list-item">
            <p>
              Built in partnership with top payment processors. HelixPay
              provides subscriptions and membership management tools on top.
            </p>
          </li>
          <li class="first-list-item">
            <p>
              Friendly design and customized "tool tips" introduce recurring
              payments to the market to increase adoption.
            </p>
          </li>
          <li class="first-list-item">
            <p>
              Subscribers can review and adjust their preferences via HelixPay
              Customer Portal.
            </p>
          </li>
        </div>

        <div class="recurring-payments-right-part">
          <img
            v-motion
            src="img/Website-Illustration.png"
            alt=""
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.to-hero {
  margin: 0;
}

.hero {
  background-color: #f3f7f8;
}

.recurring-main {
  width: 100%;
  background-color: #f3f7f8;
}

.recurring-payments {
  width: 71rem;
  margin: auto;
  padding-top: 2rem;
  border-left: 2px solid white;
}

.recurring-payments h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  margin-left: -2px;
  padding-left: 25px;
  border-left: 2px solid #25a4e1;
}

.wordings {
  font-weight: 600;
  color: #25a4e1;
}

.recurring-payments-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  margin: auto;
  margin-top: 2rem;
  padding-bottom: 3.5rem;
  width: 95%;
  align-items: center;
  justify-content: center;
}

.first-list-item {
  list-style-type: disc;
  list-style-position: outside;
  padding: 20px 0 10px 20px;
}

.recurring-payments-left-part li {
  font-size: 1rem;
  top: -10rem;
  line-height: 2;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 15%;
  background-size: 1rem;
}

.recurring-payments-left-part p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

.recurring-payments-left-part,
.recurring-payments-right-part {
  width: 100%;
}

.recurring-payments-right-part img {
  width: 50%;
  height: auto;
  margin: auto;
  display: block;
}

@media only screen and (max-width: 1400px) {
  .recurring-payments {
    width: 60rem;
  }

  .recurring-payments-info {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1050px) {
  .recurring-payments {
    width: 40rem;
  }

  .recurring-payments-info {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .recurring-payments {
    width: 90%;
    border-left: none;
  }

  .recurring-payments-info {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .recurring-payments h4 {
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .recurring-payments h1 {
    font-size: 1.5rem;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  .recurring-payments-left-part li {
    background-position: 0% 3%;
  }

  .recurring-payments-info img {
    width: 50%;
    margin-top: 2rem;
  }

  .banks {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
